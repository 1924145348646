import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import SingleSelect from '@/components/forms/select/SingleSelect';

import Dots from './Dots';
import styles from './Pagination.module.css';

const DISPLAY_OPTIONS = {
    DISPLAY_ALL: 'DISPLAY_ALL',
    DISPLAY_FIRST: 'DISPLAY_FIRST',
    DISPLAY_FIRST_PLUS: 'DISPLAY_FIRST_PLUS',
    DISPLAY_MIDDLE: 'DISPLAY_MIDDLE',
    DISPLAY_LAST_MINUS: 'DISPLAY_LAST_MINUS',
    DISPLAY_LAST: 'DISPLAY_LAST',
};

function computeDisplayMode(selectedPage, totalPages, ellipsisThreshold, windowSize) {
    let result = DISPLAY_OPTIONS.DISPLAY_MIDDLE;

    if (windowSize >= totalPages || (totalPages - 1) <= ellipsisThreshold
        || ((selectedPage < windowSize - 1) && (windowSize - 1 === totalPages - 1))) {
        result = DISPLAY_OPTIONS.DISPLAY_ALL;
    } else if (selectedPage < windowSize - 1) {
        result = DISPLAY_OPTIONS.DISPLAY_FIRST;
    } else if (selectedPage - 1 <= 1) {
        result = DISPLAY_OPTIONS.DISPLAY_FIRST_PLUS;
    } else if (selectedPage > totalPages - windowSize) {
        result = DISPLAY_OPTIONS.DISPLAY_LAST;
    } else if (selectedPage + 1 >= totalPages - windowSize) {
        result = DISPLAY_OPTIONS.DISPLAY_LAST_MINUS;
    }

    return result;
}

interface Props {
    readonly initialPage: number,
    readonly totalPages: number,
    readonly onPageChange?: (page: number) => void,
    readonly windowSize?: number,
    readonly ellipsisThreshold?: number,
    readonly allowPageSizeOptions?: boolean,
    readonly pageSizeOptions?: any[],
    readonly setSelectedSize?: (size: number) => void,
    readonly selectedSize?: number,
}

function Pagination({
    initialPage,
    totalPages,
    onPageChange = () => { },
    windowSize = 5,
    ellipsisThreshold = 3,
    allowPageSizeOptions = false,
    pageSizeOptions = null,
    setSelectedSize = () => { },
    selectedSize = 0,
} : Props) {
    const [selectedPage, setSelectedPage] = useState(initialPage);

    const initialDisplayMode = computeDisplayMode(
        selectedPage,
        totalPages,
        ellipsisThreshold,
        windowSize,
    );

    const [displayMode, setDisplayMode] = useState(initialDisplayMode);

    const handlePageSelect = (page) => {
        setSelectedPage(() => page);
        onPageChange(page);
    };

    const handleKeyPress = (event, i) => {
        if (event.key === ' ' || event.key === 'Enter') {
            handlePageSelect(i);
        }
    };

    useEffect(() => {
        const newMode = computeDisplayMode(selectedPage, totalPages, ellipsisThreshold, windowSize);
        setDisplayMode(() => newMode);
    }, [selectedPage, totalPages, ellipsisThreshold, windowSize]);

    const getNumbersInRange = (start, end) => {
        const numbers = [];

        for (let i = start; i <= end; i += 1) {
            // TODO: Use a <button> below, to deal with the deprecation...
            numbers.push(
                <div
                    key={`page-${i}`}
                    className={`${styles.pagination} ${(selectedPage === i) && styles.selected}`}
                    onClick={() => handlePageSelect(i)}
                    onKeyDown={(event) => {
                        if (event.key === ' ' || event.key === 'Enter') {
                            handleKeyPress(event, i);
                            event.preventDefault();
                        }
                    }}
                    role="menuitem"
                    tabIndex={0}
                >
                    {i + 1}
                </div>,
            );
        }

        return numbers;
    };

    return (
        <>
            <nav className={styles.container}>
                <span
                    className={styles.previous}
                    onClick={() => handlePageSelect(Math.max(selectedPage - 1, 0))}
                    onKeyDown={(event) => {
                        if (event.key === ' ' || event.key === 'Enter') {
                            handlePageSelect(Math.max(selectedPage - 1, 0));
                            event.preventDefault();
                        }
                    }}
                    role="menuitem"
                    aria-label="Previous page"
                    tabIndex={0}
                >
                    Previous
                </span>
                {displayMode === DISPLAY_OPTIONS.DISPLAY_ALL && (
                    getNumbersInRange(0, totalPages - 1)
                )}
                {displayMode === DISPLAY_OPTIONS.DISPLAY_FIRST && ([
                    getNumbersInRange(0, windowSize - 1),
                    <Dots key="displayFirst" />,
                    getNumbersInRange(totalPages - 1, totalPages - 1),
                ])}
                {displayMode === DISPLAY_OPTIONS.DISPLAY_FIRST_PLUS && ([
                    getNumbersInRange(0, selectedPage + 1),
                    <Dots key="displayFirstPlus" />,
                    getNumbersInRange(totalPages - 1, totalPages - 1),
                ])}
                {displayMode === DISPLAY_OPTIONS.DISPLAY_MIDDLE && ([
                    getNumbersInRange(0, 0),
                    <Dots key="displayMiddlePre" />,
                    getNumbersInRange(selectedPage - 1, selectedPage + 1),
                    <Dots key="displayMiddlePost" />,
                    getNumbersInRange(totalPages - 1, totalPages - 1),
                ])}
                {displayMode === DISPLAY_OPTIONS.DISPLAY_LAST_MINUS && ([
                    getNumbersInRange(0, 0),
                    <Dots key="displayLastMinus" />,
                    getNumbersInRange(selectedPage - 1, totalPages - 1),
                ])}
                {displayMode === DISPLAY_OPTIONS.DISPLAY_LAST && ([
                    getNumbersInRange(0, 0),
                    <Dots key="displayLast" />,
                    getNumbersInRange(totalPages - windowSize, totalPages - 1),
                ])}
                <span
                    className={styles.next}
                    onClick={() => handlePageSelect(Math.min(selectedPage + 1, totalPages - 1))}
                    onKeyDown={(event) => {
                        if (event.key === ' ' || event.key === 'Enter') {
                            handlePageSelect(Math.min(selectedPage + 1, totalPages - 1));
                            event.preventDefault();
                        }
                    }}
                    role="menuitem"
                    aria-label="Next page"
                    tabIndex={0}
                >
                    Next
                </span>
            </nav>
            {(allowPageSizeOptions && (
                <div className="w-full md:w-60 lg:w-80 ml-2 md:ml-5 flex flex-row justify-center sm:justify-around items-center">
                    <div className="w-[100px]">
                        <Formik
                            initialValues={{ pageSize: selectedSize }}
                            onSubmit={() => null}

                        >
                            <SingleSelect
                                name="pageSize"
                                options={pageSizeOptions}
                                onChange={(option) => setSelectedSize(option.value)}
                            />
                        </Formik>
                    </div>
                    <span className="w-6/12 text-center">items per page</span>
                </div>
            ))}
        </>
    );
}

export default Pagination;
