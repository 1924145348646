export enum SortDirection {
    ASC = 'asc',
    ASC_NULLS_FIRST = 'ascNullsFirst',
    ASC_NULLS_LAST = 'ascNullsLast',
    DESC = 'desc',
    DESC_NULLS_FIRST = 'descNullsFirst',
    DESC_NULLS_LAST = 'descNullsLast',
}

export const PAGE_SIZE = 25;

export function cmp(val1, val2) {
    // Handle cases where comparing things is not meaningful.
    if ((val1 === null || val2 === null) || (typeof val1 !== typeof val2)) {
        return null;
    }

    // Handle strings in a Unicode-aware way.
    if (typeof val1 === 'string') {
        return (val1).localeCompare(val2);
    }

    // Handle numeric comparisons.
    if (val1 > val2) { return 1; }
    if (val1 < val2) { return -1; }
    return 0;
}

export function sortIsAsc(direction: SortDirection) {
    return direction === SortDirection.ASC
        || direction === SortDirection.ASC_NULLS_FIRST
        || direction === SortDirection.ASC_NULLS_LAST;
}

export function toggleDirection(direction: SortDirection) {
    switch (direction) {
    case SortDirection.ASC:
        return SortDirection.DESC;
    case SortDirection.DESC:
        return SortDirection.ASC;
    case SortDirection.ASC_NULLS_FIRST:
        return SortDirection.DESC_NULLS_FIRST;
    case SortDirection.DESC_NULLS_FIRST:
        return SortDirection.ASC_NULLS_FIRST;
    case SortDirection.ASC_NULLS_LAST:
        return SortDirection.DESC_NULLS_LAST;
    case SortDirection.DESC_NULLS_LAST:
        return SortDirection.ASC_NULLS_LAST;
    default:
        throw new Error(`Invalid sort direction: ${direction}`);
    }
}

export function handleColumnSort(sortColumn, setSortColumn, sortDirection, setSortDirection) {
    return (column, defaultDirection) => {
        if (sortColumn && sortColumn !== column) { setSortColumn(column); return; }

        const newDirection = sortColumn && sortColumn === column
            ? toggleDirection(sortDirection) : defaultDirection;

        setSortDirection(newDirection);
    };
}

export default {};
