import React, { useState } from 'react';

import Pagination from '@/components/commons/pagination';
import IconWrapper from '@/components/commons/IconWrapper';
import ArrowUp from '@/components/commons/icons/ArrowUp';
import Title from '@/components/commons/page-elements/Title';
import { SortDirection, PAGE_SIZE, sortIsAsc } from '@/services/sorting';

import styles from './SmartTable.module.css';

function SmartTable({
    externalStyles = null,
    columns,
    data,
    title = () => null,
    paginationState = null,
    sortColumn = null,
    sortDirection = SortDirection.ASC,
    setSelectedSize = (_size) => { },
    selectedSize = 25,
    pageSizeOptions = [],
    allowPageSizeOptions = false,
    selectedRows = [],
    setParentPage = (_page) => { },
}) {
    const fullStyles = { ...styles, ...externalStyles };
    const [page, setPage] = useState(paginationState ? paginationState.initialPage : 0);
    const pageSize = allowPageSizeOptions ? selectedSize : PAGE_SIZE;

    const roundedRow = externalStyles.roundedRow ? fullStyles.bodyRow : '';

    const totalPages = Math.ceil(data.length / pageSize);
    const getDisplayedData = (idx, rows) => {
        const start = idx * pageSize;
        return rows.slice(start, start + pageSize);
    };

    const handleKeyPress = (event, column) => {
        if (event.key === ' ' || event.key === 'Enter') {
            column.onSort(column.id);
            event.preventDefault();
        }
    };

    const renderPagination = () => {
        if (allowPageSizeOptions && paginationState) {
            return (
                <div className="flex items-center justify-center py-6">
                    <Pagination
                        initialPage={paginationState.initialPage}
                        totalPages={totalPages}
                        windowSize={paginationState.windowSize}
                        onPageChange={(selectedPage) => {
                            setParentPage(selectedPage);
                            setPage(selectedPage);
                        }}
                        allowPageSizeOptions={allowPageSizeOptions}
                        selectedSize={selectedSize}
                        pageSizeOptions={pageSizeOptions}
                        setSelectedSize={setSelectedSize}
                    />
                </div>
            );
        }

        if (paginationState && totalPages > 1) {
            return (
                <div className="flex justify-center py-6">
                    <Pagination
                        initialPage={paginationState.initialPage}
                        totalPages={totalPages}
                        windowSize={paginationState.windowSize}
                        onPageChange={setPage}
                    />
                </div>
            );
        }

        return null;
    };

    return (
        <div className={fullStyles.componentWrapper}>
            <div className={fullStyles.wrapper}>
                <div className={`p-4 ${fullStyles.title}`}>
                    <Title>
                        {title()}
                    </Title>
                </div>
                <table className={`${fullStyles.table} table-auto w-full`}>
                    <thead className={fullStyles.header}>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    key={column.id}
                                    scope="col"
                                    className={fullStyles.headerCell}
                                >
                                    <div className={`flex ${column.center && 'flex flex-col'} items-center`}>
                                        {column.onSort
                                            && (
                                                <span
                                                    className={fullStyles.headerLink}
                                                    onClick={() => column.onSort(column.id)}
                                                    onKeyDown={
                                                        (evt) => handleKeyPress(evt, column)
                                                    }
                                                    role="menuitem"
                                                    tabIndex={0}
                                                >
                                                    {column.label}
                                                    {sortColumn === column.id
                                                        && (
                                                            <span className={fullStyles.sort}>
                                                                <IconWrapper
                                                                    svg={<ArrowUp className="h-3 w-3 m-auto" />}
                                                                    className={`text-cleanSlate ${sortIsAsc(sortDirection) ? 'rotate-180' : null}`}
                                                                />
                                                            </span>
                                                        )}
                                                </span>
                                            )}

                                        {!column.onSort
                                            && (
                                                <span className={fullStyles.headerPlain}>{typeof column.label === 'string' ? column.label : column.label()}</span>
                                            )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={fullStyles.body}>
                        {getDisplayedData(page, data).map((row) => (
                            <tr key={row.id} className={roundedRow}>
                                {columns.map((column) => {
                                    const key = `${row.id}-${column.id}`;
                                    const isSelected = selectedRows.includes(row.id);
                                    const baseClass = fullStyles.bodyCell;
                                    // fullStyles[column.id] allows targetig specific columns
                                    // Useful for styling
                                    let effectiveClass = `${baseClass} ${fullStyles[column.id]}`;
                                    if (isSelected) {
                                        effectiveClass = `${effectiveClass} ${fullStyles.selected}`;
                                    }
                                    if (column.shrink) {
                                        effectiveClass = `${effectiveClass} ${fullStyles.shrunk}`;
                                    }
                                    return (
                                        <td key={`${key}-td`} className={effectiveClass}>
                                            {column.render(key, row)}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {renderPagination()}
        </div>
    );
}

export default SmartTable;
